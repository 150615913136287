/*
  A generic tooltip component

  ```
    <drb-tooltip>
      <div>The element the popover is anchored to</div>
      <div slot="tooltip-content">Any content</div>
    </drb-tooltip>

    or

    <drb-tooltip content="Just text content">
      <div>The element the popover is anchored to</div>
    </drb-tooltip>
  ```

  Attributes:
    - `placement` (string) - the placement of the popover. (default: "top-center") https://shoelace.style/components/popup#placement
    - `distance` (number) - the distance between the tooltip and the anchor element.
    - `content` (string) - the text-only content of the tooltip.
    - `max-width` (string) - the maximum width of the tooltip.
    - `mouse-only` (boolean) - whether the tooltip should only show on mouse hover events
*/

import { LitElement, unsafeCSS, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import styles from './drb-tooltip.scss?inline';

@customElement('drb-tooltip')
class DrbTooltip extends LitElement {
  static styles = unsafeCSS(styles);
  isTouchDevice = matchMedia('(hover: none)').matches;

  @property({ type: String })
  placement = 'top-center';

  @property({ type: Number })
  distance = 6;

  @property({ type: String })
  content = '';

  @property({ attribute: 'max-width', type: String })
  maxWidth = '310px';

  @property({ attribute: 'mouse-only', type: Boolean })
  mouseOnly = false;

  @state()
  active = false;

  @property({ type: String })
  strategy: 'absolute' | 'fixed' = 'fixed';

  render() {
    return html`
      <drb-popover
        style=${`--tooltip-max-width: ${this.maxWidth};`}
        active="${this.active || nothing}"
        shift
        shift-padding="10"
        auto-size="horizontal"
        strategy="${this.strategy}"
        hover-bridge
        placement="${this.placement}"
        distance="${this.distance}"
        class="tooltip"
        @mouseout="${() => { this.active = false; }}"
        @mouseover="${() => { this.active = this.mouseOnly ? !this.isTouchDevice : true; }}"
      >
        <slot></slot>

        <slot
          name="tooltip-content"
          slot="popover-content"
          class="tooltip__content"
        >
          ${this.content}
        </slot>
      </drb-popover>
    `;
  }
}

export { DrbTooltip };
