/*
  A generic popover component that can be anchored to any element.

  ```
    <drb-popover active>
      <button>The element the popover is anchored to</button>
      <div slot="popover-content">Any content</div>
    </drb-popover>
  ```

  This component is a wrapper around the `sl-popup` component from the Shoelace library.

  It reverses the default slot arrangement so we can render our trigger in the light dom,
  and the popover content in the shadow dom.

  It is styleable via CSS custom properties, and can be controlled via the `active` attribute.

  Typically, this low-level component would be used in conjunction with higher-level
  components, (not usually by itself) e.g. dropdowns, tooltips, etc.

  Attributes:
    - `placement` (string) - the placement of the popover. (default: "bottom-end") https://shoelace.style/components/popup#placement
    - `distance` (number) - the distance between the popover and the anchor element.
    - `sync` ('' | 'width' | 'height' | 'both') - can make the popover the same width/height as the trigger (default: '') https://shoelace.style/components/popup#syncing-with-the-anchors-dimensions
*/

import { LitElement, unsafeCSS, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import styles from './drb-popover.scss?inline';

@customElement('drb-popover')
class DrbPopover extends LitElement {
  static styles = unsafeCSS(styles);

  @property({ attribute: 'active', reflect: true, type: Boolean })
  isActive = false;

  @property({ type: Boolean })
  shift = false;

  @property({ type: Boolean })
  flip = false;

  @property({ attribute: 'shift-padding', type: Number })
  shiftPadding;

  @property({ attribute: 'hover-bridge', type: Boolean })
  hoverBridge = false;

  @property({ type: String })
  placement = 'bottom-end';

  @property({ type: Number })
  distance = 0;

  @property({ type: String })
  sync: '' | 'width' | 'height' | 'both' = '';

  @property({ type: String })
  autoSize: '' | 'vertical' | 'horizontal' | 'both' = '';

  @property({ type: String })
  strategy: 'absolute' | 'fixed' = 'absolute';

  render() {
    return html`
      <sl-popup
        active="${this.isActive || nothing}"
        shift="${this.shift || nothing}"
        shift-padding="${this.shiftPadding || nothing}"
        hover-bridge="${this.hoverBridge || nothing}"
        flip="${this.flip || nothing}"
        placement="${this.placement}"
        distance="${this.distance}"
        sync="${this.sync}"
        autoSize="${this.autoSize}"
        strategy="${this.strategy}"
      >
        <slot slot="anchor"></slot>
        <slot name="popover-content"></slot>
      </sl-popup>
    `;
  }
}

export { DrbPopover };
