// shoelace web components
import '~/entrypoints/shoelace-overrides.css';

import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/dialog/dialog';
import '@shoelace-style/shoelace/dist/components/popup/popup';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';

// dribbble web components
import '~/web-components/drb-type-ahead/drb-type-ahead';
import '~/web-components/drb-popover/drb-popover';// should be imported before tooltip
import '~/web-components/drb-tooltip/drb-tooltip';
import '~/web-components/drb-dropdown-option/drb-dropdown-option'; // should be imported before dropdown
import '~/web-components/drb-dropdown/drb-dropdown';
import '~/web-components/drb-toast/drb-toast';
import '~/web-components/drb-toast/drb-toast-helpers';

import '~/web-components/drb-avatar/drb-avatar';
import '~/web-components/drb-dialog/drb-dialog'; // should be imported before drb-confirm
import '~/web-components/drb-confirm/drb-confirm';
import '~/web-components/drb-horizontal-overflow-arrows/drb-horizontal-overflow-arrows';
import '~/web-components/drb-infinite-scroll/drb-infinite-scroll';
import '~/web-components/drb-character-counter/drb-character-counter';
import '~/web-components/drb-autocomplete-option/drb-autocomplete-option';
import '~/web-components/drb-autocomplete/drb-autocomplete'; // should be imported after drb-popover & drb-type-ahead
import '~/web-components/drb-clearable-input/drb-clearable-input';
import '~/web-components/drb-clearbit/drb-clearbit'; // should be imported after drb-dropdown & drb-type-ahead

// dribbble web component helpers
import { DrbDialog } from '~/web-components/drb-dialog/drb-dialog-helpers';

DrbDialog.bindListeners();
