/*
  Displays an avatar with an optional online status indicator (if `user-id` is present).
  Avatar size can be set via CSS custom property `--avatar-size`.

  Example:

  Automatically determine online status for a user
  ```
    <drb-avatar user-id="<%= @user.id %>">
      <%= simple_avatar(user) %>
    </drb-avatar>
  ```

  Manually set online status (if wanting to server render the online status)
  ```
    <drb-avatar <%= 'online' if user.recently_active? %>>
      <%= simple_avatar(user) %>
    </drb-avatar>
  ```
*/

import { LitElement, unsafeCSS, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { onNextRepaint } from '~/shared/utils/animation';
import { getCSRFToken } from '~/shared/utils/request';
import styles from './drb-avatar.scss?inline';

@customElement('drb-avatar')
class DrbAvatar extends LitElement {
  static styles = unsafeCSS(styles);

  static onlineUsers = new Set<string>();
  static pendingUserIds = new Set<string>();
  static intervalId = null;

  @property({ attribute: 'user-id', type: Number })
  userId;

  @property({ type: Boolean })
  online = false;

  @state()
  initialized = false;

  connectedCallback() {
    super.connectedCallback();
    if (!this.userId || this.online) return;

    if (DrbAvatar.onlineUsers.has(this.userId)) {
      this.online = true;
    } else {
      // Add to batched pending list to fetch online status in bulk
      DrbAvatar.pendingUserIds.add(this.userId);
    }

    if (!DrbAvatar.intervalId) {
      DrbAvatar.intervalId = setInterval(() => {
        DrbAvatar.fetchOnlineStatus();
      }, 1000);
    }
  }

  firstUpdated() {
    onNextRepaint(() => {
      // wait for the next repaint to ensure reveal animation plays
      this.initialized = true;
    });
  }

  // Fetch online status for all pending user IDs
  static async fetchOnlineStatus() {
    if (DrbAvatar.pendingUserIds.size === 0) return;

    const userIds = Array.from(DrbAvatar.pendingUserIds);
    DrbAvatar.pendingUserIds.clear();

    try {
      const response = await fetch('/users/active_sessions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': getCSRFToken(),
        },
        body: JSON.stringify({ user_ids: userIds }),
      });

      const onlineUserIds = await response.json();
      onlineUserIds.forEach(userId => DrbAvatar.onlineUsers.add(userId));

      document.querySelectorAll('drb-avatar').forEach((avatar: DrbAvatar) => {
        if (DrbAvatar.onlineUsers.has(avatar.userId)) {
          avatar.online = true;
        }
      });
    } catch (error) {
      console.error('Failed to fetch online status:', error);
    }
  }

  render() {
    return html`
      <slot></slot>
      <drb-tooltip
        ?hidden="${!this.online || !this.initialized}"
        content="Online"
        mouse-only
        placement="right"
        strategy="absolute"
      >
        <div class="green-dot" ?hidden="${!this.online || !this.initialized}"></div>
      </drb-tooltip>
    `;
  }
}

export { DrbAvatar };
